.text-primary1 {
  color: #fff;
}
#main {
  height: 14em;
}

#usersList,
#renderhere3,
#messages,
#allegati {
  overflow-y: auto;
  height: 18em;
}

.card {
  background-color: #040404b7;
}

#messages {
  background-color: #00000042;
  border-color: #ed283a;
  border-style: none;
  border-width: 2px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 24px -1px rgba(255, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 24px -1px rgba(255, 0, 0, 0.5);
  box-shadow: 0px 0px 24px -1px rgba(255, 0, 0, 0.5);
}

.form-control {
  background-color: rgba(0, 0, 0, 0.747);
  border-color: #ed283a;
  color: #fff;
}

.form-control:focus {
  background-color: rgb(0, 0, 0);
  border-color: #ed283a;
  box-shadow: 0 0 0 0.2rem #dc35463f;
  color: #fff;
}

.link-section {
  margin-bottom: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  left: -100px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(30, 30, 30, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aa1824de;
  border: solid 2px transparent;
  border-radius: 14px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ed283a;
}

.msgBoxMyself {
  background-color: #ffffffff;
  color: #0a0000;
  padding: 2px 8px;
  width: 80%;
  max-width: 500px;
  margin: 6px;
  margin-left: auto;
  margin-right: 18px;
  border-radius: 12px;
  font-size: 1.1rem;
}

.msgBoxOthers {
  background-color: #ffffffff;
  color: #000000;
  padding: 2px 8px;
  width: 80%;
  max-width: 500px;
  margin: 6px;
  margin-left: 18px;
  border-radius: 12px;
  font-size: 1.1rem;
}

.msgBoxUsername {
  margin-top: 0px;
  padding-left: 6px;
  color: #ed283a;
  /* text-decoration: underline; */
  /* font-size: 0.8em; */
  font-weight: bold;
  font-size: .98rem;
}
.msgBoxBody {
  margin-top: -1px;
  padding-left: 6px;
  padding-right: 16px;
  margin-bottom: 0px;
  font-size: .98rem;
  line-height: .98rem;
}
.msgBoxDate {
  margin-top: -4px;
  text-align: right;
  color: #313131;
  font-size: 0.78em;
}

.dayBox {
  background-color: #3f3f3f;
  color: #ffffff;
  padding: 4px;
  min-width: 20%;
  max-width: 35%;
  margin: 0 auto;
  text-align: center;
}

h3 {
  padding-left: 15px;
  font-size: 1.6rem;
}

.form-control.chat {
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  -webkit-box-shadow: 0px 0px 24px -1px rgba(255, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 24px -1px rgba(255, 0, 0, 0.5);
  box-shadow: 0px 0px 24px -1px rgba(255, 0, 0, 0.5);
}

#replyBtn {
  background-color: #000000be;
  color:#ed283a;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}

.chat-container {
  max-width: 900px;
}


@media only screen and (max-width: 480px) {
  .msgBoxMyself {
    width: 90%;
    padding: 2px 6px;
    margin-right: 9px;
    border-radius: 12px;
    font-size: 1.1rem;
  }

  .msgBoxOthers {
    width: 90%;
    padding: 2px 6px;
    margin: 6px;
    margin-left: 9px;
    font-size: 0.9rem;
  }

  .msgBoxUser name {
    margin-top: 0px;
    padding-left: 6px;
  }
  .msgBoxBody {
    margin-top: 0px;
    padding-left: 6px;
    padding-right: 16px;
    margin-bottom: 9px;
    line-height: 0.9rem;
  }
  .msgBoxDate{
    margin-top: -14px;
    font-size: 0.78em;
  }

  .dayBox {
    padding: 4px;
    min-width: 20%;
    max-width: 35%;
  }

  #messages {
    margin: 0 7%;
    height: 14em;
  }
  .player {
    margin: 0 7%;
  }
  .logout {
    font-size: 1rem;
  }
  .logo {
    height: 70px;
    margin-top: 6px;
    margin-bottom: 10px;
  }

  .toggle-mode {
    font-size: 0.6rem;
  }
}


@media only screen and (min-height: 1200px) {
  #messages {
    height: 18em;
  }
}

@media only screen and (min-height: 1340px) {
  #messages {
    height: 20em;
  }
}

@media only screen and (min-height: 1400px) {
  #messages {
    height: 24em;
  }
}

@media only screen and (min-height: 1430px) {
  #messages {
    height: 26em;
  }
}